// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-404-template-tsx": () => import("./../../../src/templates/404-template.tsx" /* webpackChunkName: "component---src-templates-404-template-tsx" */),
  "component---src-templates-index-template-tsx": () => import("./../../../src/templates/index-template.tsx" /* webpackChunkName: "component---src-templates-index-template-tsx" */),
  "component---src-templates-post-template-tsx": () => import("./../../../src/templates/post-template.tsx" /* webpackChunkName: "component---src-templates-post-template-tsx" */),
  "component---src-templates-redirect-template-tsx": () => import("./../../../src/templates/redirect-template.tsx" /* webpackChunkName: "component---src-templates-redirect-template-tsx" */)
}

